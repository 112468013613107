<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> TRANSAKSI VISA</v-card-title>

        <v-row class="mb-6" no-gutters>
          <v-col sm="5" md="6">
            <!-- Kiri -->
            <v-card-text class="text-no-wrap pt-1 ps-1">
              <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item v-for="(item, i) in items" :key="i" :color="item.color" :icon="item.icon" fill-dot>
                  <v-card :color="item.color" dark>
                    <v-card-subtitle-1>
                      <p class="font-weight-medium" style="padding: 10px; color: #ffffff">{{ item.isi }}</p>
                    </v-card-subtitle-1>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-col>
          <v-col sm="5" offset-sm="2" md="6" offset-md="0"> Kanan </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiAccountMultipleCheck, mdiCashMultiple, mdiWallet } from '@mdi/js'
export default {
  data() {
    return {
      items: [
        {
          color: 'error',
          icon: mdiAccountMultipleCheck,
          isi: 'Pilih Client yang  akan membayar VISA. ',
        },
        {
          color: 'success',
          icon: mdiCashMultiple,
          isi: 'Pilih Detail Biaya sesuai dengan kebutuhan pembuatan VISA Client. ',
        },
        {
          color: 'accent',
          icon: mdiWallet,
          isi: 'Proses Bayar sesuai dengan nominal melalui BANK yang tersedia yaitu : Mandiri, BNI, Permata, Bank Lainnya. ',
        },
      ],

      // icons
      icons: {
        mdiAccountMultipleCheck,
        mdiCashMultiple,
        mdiWallet,
      },
    }
  },
}
</script>
